.video-gallery-summary-container {
  align-items: center;
  background-color: transparent;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.video-gallery-summary-image {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.video-gallery-summary-nav-button {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  z-index: 2;
}

.previous-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.video-gallery-summary-image-count {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  bottom: 10px;
  font-size: 10pt;
  left: 10px;
  padding: 5px 10px;
  position: absolute;
  z-index: 2;
}
