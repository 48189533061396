.photo-feed-item-container {
  align-items: center;
  border: 0px solid;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.photo-feed-item-image-wrapper {
  align-items: center;
  display: flex; 
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.photo-feed-item-image {
  object-fit: cover;
  object-position: center;
  user-select: none;
  width: 100%;
}

.photo-feed-item-footer {
  bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
  padding: 20px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.photo-feed-item-title {
  font-size: 11pt;
  font-weight: bold;
}

.photo-feed-item-caption {
  font-size: 10pt;
  font-weight: normal;
}
