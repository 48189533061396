.login-error-container {
    border-width: 2px;
    border-style: solid;
    border-radius: 7px;
    box-sizing: border-box;
    display: flex;
    font-size: 9pt;
    justify-content: center;
    margin-top: 2px;
    padding: 12px;
    width: 80%;
}