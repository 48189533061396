.properties-checkbox {
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
}

.properties-checkbox-label {
    cursor: pointer;
    font-size: 9pt;
    margin-left: 8px;
    margin-top: 2px;
    user-select: none;
}

.properties-container {
    border-left-width: 1px;
    border-left-style: dotted;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 98%; 
    max-width: 230px;
    min-width: 230px;
    transition: width 0.1s ease-in;
    user-select: none;
}

.properties-font-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-content: left;
    margin-bottom: 8px;
    margin-left: 0px;
    width: auto;
}

.properties-font-input {
    border-width: 1px;
    border-style: solid;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 7px;
    text-align: center;
    width: 60px;
}

.properties-font-input-wrapper {
    align-items: left;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    font-size: 9pt;
    margin-top: 8px;
    padding-left: 2px;
}

.properties-font-label {
    align-items: left;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    font-size: 9pt;
    padding-left: 2px;
}

.properties-font-select {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    padding: 8px;
    text-align: left;
    width: 100%;
}

.properties-input,
.properties-select,
.properties-textarea {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    padding: 12px;
    margin-top: 10px;
    margin-bottom: 18px;
    width: 100%;
}

.properties-label {
    font-size: 9pt;
    margin-left: 2px;
}

.properties-model {
    flex-grow: 1;
    box-sizing: border-box;
    flex-grow: 1;
    overflow-y: auto;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 8px;
    opacity: 0;
    transition: opacity 0.1s linear; /* Smooth fade transition */
}
