.bookmarks-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.bookmarks-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

.bookmarks-wrapper-mobile {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 10px;
    width: 100%;
}

.bookmarks-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.bookmarks-column-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.bookmarks-object {
    margin-bottom: 10px;
    width: 100%;
}
