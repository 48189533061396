.context-menu-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
  opacity: 0.5;
  z-index: 1000; /* Ensure it is below the popup */
}

.context-menu-popup-container {
  position: fixed;
  border-radius: 9px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 80vh; /* Limit the maximum height */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
  overflow-x: hidden; /* Disable horizontal scrolling */
  z-index: 1001; /* Higher than the mask */
}
