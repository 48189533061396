.document-container {
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-shrink: 1;
    gap: 12px;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 12px;
    padding-left: 16px;
    padding-right: 20px;
    width: 100%;
}

.document-filename {
    font-size: 10pt;
    font-weight: bold;
}