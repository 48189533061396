.app-settings-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    position: relative;
}

.app-settings-title {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 16pt;
    font-weight: bold;
    gap: 6px;
    min-height: 30px;
    justify-content: left;
    padding-left: 18px;
    text-wrap: nowrap;
    user-select: none;
    width: 100%;
}

.app-settings-content {
    flex-grow: 1;
    padding: 20px;
    width: 600px;
}

.app-settings-save-button-wrapper {
    bottom: 20px;
    height: 50px;
    justify-content: left;
    position: absolute;
    right: 20px;
    width: 50px;
}

.app-settings-status-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-left: 30px;
}

@media (max-width: 768px) {

    .app-settings-content {
        width: 100%;
    }

}