.menu-item-container {
    align-items: center;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 2px;
    overflow: hidden;
    padding: 3px;
    padding-left: 8px;
    transition: background-color 0.2s;
}

.menu-item-left {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    width: 100%;
}

.menu-item-right {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 30px;
}

.menu-item-text {
    font-size: 10pt;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 8px;
    overflow: hidden;
    user-select: none;
    white-space: nowrap;
    width: 150px;
}