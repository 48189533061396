.room-add-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.room-add-top {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.room-add-participants {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    overflow-y: auto;
}

.room-add-bottom {
    height: auto;
    margin-bottom: 20px;
}

.room-add-participants-buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
    margin-top: 20px;
}

.room-add-participants-button {
    border-width: 0px;
    border-radius: 7px;
    color: white;
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    justify-content: center;
    padding: 12px;
    width: 200px;
}