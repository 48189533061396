.note-form-content {
    background-color: transparent;
    border-color: transparent;
    font-size: 11pt;
    font-weight: normal;
    border-width: 1px;
    border-style: solid;
    outline: none;
    width: 100%;
    resize: none;
    /* Disable manual resizing */
    overflow: hidden;
    /* Hide scrollbar */
}

/* Auto-resize textarea */
.note-form-content:focus {
    overflow-y: auto;
    /* Show scrollbar on focus if needed */
}

@media (max-width: 600px) {

    .note-form-content {
        overflow: hidden;
        /* Hide scrollbars */
        -webkit-overflow-scrolling: touch;
        /* Enable smooth scrolling on iOS */
    }

    .note-form-content:focus {
        overflow-y: auto;
        /* Show scrollbar on focus if needed */
        /* Ensure scrollbar remains hidden but allows scrolling */
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* Internet Explorer and Edge */
    }

    .note-form-content::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari, and Opera */
    }

}