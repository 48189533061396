.calendar-day-container {
  flex-shrink: 1;
  overflow-y: auto;
  position: relative;
  height: 100%;
  width: 100%;
}

.calendar-day-all-day {
  margin-left: 20px;
  margin-right: 20px;
  height: auto;
}

.calendar-day-wrapper {
  position: relative;
  margin-top: 20px;
  width: 100%;
}

.calendar-day-events-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  /* Ensure this is above hour blocks */
  pointer-events: none;
}

.calendar-day-event-details {
  max-height: 100px;
  overflow: hidden;
}

.calendar-day-event-title {
  font-size: 9pt;
  font-weight: bold;
  margin-bottom: 4px;
}

.calendar-day-event-time {
  font-size: 8pt;
  font-weight: normal;
}

.calendar-day-event-detail {
  font-size: 8pt;
  font-weight: normal;
  margin-top: 6px;
}