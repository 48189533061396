.model-delete-message {
    font-size: 10pt;
    line-height: 1.5em;
    margin: 16px;
    text-align: center;
}

.model-delete-buttons {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    width: 100%;
}

.model-delete-button {
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 10pt;
    font-weight: bold;
    padding: 10px 20px;
}