.chat-post-form-button-container {
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    height: 42px;
    margin-right: 14px;
    margin-top: 6px;
    padding: 12px;
    text-align: center;
    width: auto;
}