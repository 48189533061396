.calendar-month-day-container {
  border-bottom: 1px solid;
  border-right: 1px solid;
  cursor: pointer;
  padding: 2px;
  overflow: hidden;
  text-align: center;
  width: calc(100% / 7);
  z-index: 1;
}

.calendar-month-day-header {
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 9pt;
}

.calendar-month-day:last-child {
  border-right: none;
}

.calendar-month-day-events {
  flex-shrink: 1;
  height: 100%;
  overflow-y: hidden;
  width: 100%;
}
