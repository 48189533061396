.calendar-month-event-container {
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  height: 18px;
  justify-content: left;
  margin-bottom: 2px;
  overflow: hidden;
  padding-left: 6px;
  padding-top: 2px;
  pointer-events: auto;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.calendar-month-event-title {
  font-size: 8pt;
  font-weight: bold;
  text-wrap: nowrap;
  overflow: hidden;
}
