.chat-posts-container {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 20px;
    width: 100%;
}

.chat-posts-post-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.chat-posts-post {
    flex-shrink: 1;
    font-size: 11pt;
    font-weight: normal;
    height: auto;
    line-height: 1.5em;
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
}

.chat-posts-post-timestamp {
    font-size: 9pt;
    font-weight: normal;
    margin-left: 8px;
}

.chat-posts-post-remove-container {
    cursor: pointer;
    width: 40px;
}

.chat-posts-divider {
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    margin: 20px;
}