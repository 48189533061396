.rating-summary-preview-container {
  align-items: center;
  border-radius: 3px;
  border-width: 1px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  width: auto;
  height: auto;
}

.rating-summary-preview-leading-text {
  font-size: 9pt;
}

.rating-summary-preview-trailing-text {
  font-size: 9pt;
}