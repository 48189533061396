.video-preview-container {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}

.video-preview-label {
  font-size: 10pt;
  margin-left: 12px;
}

.video-preview-wrapper {
  align-items: center;
  border-radius: 11pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 270px;
  overflow: hidden;
  width: 100%;
}

.video-preview-video{
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
}