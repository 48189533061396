.room-designer-container {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 100%;
}

.room-designer-canvas-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 12px;
}

.room-designer-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.room-designer-canvas {
  display: flex;
  flex-grow: 1;
  height: 400px;
  margin-right: 6px;
  min-height: 200px;
  padding: 2px;
  position: relative;
}

.room-designer-elements {
  width: 230px !important;
}

.room-designer-options {
  width: 230px !important;
}