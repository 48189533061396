.conversation-summary-container {
    align-items: center;
    border-radius: 11px;
    border: 0px solid;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.conversation-summary-top {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 60px;
    padding: 12px;
    width: 100%;
}

.conversation-summary-center {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
    padding-top: 0px;
    width: 100%;
}

.conversation-summary-title {
    display: flex;
    flex-direction: row;
    font-size: 10pt;
    font-weight: bold;
    justify-content: left;
    width: 100%;
}

.conversation-summary-message {
    display: flex;
    flex-direction: row;
    font-size: 8pt;
    justify-content: left;
    width: 100%;
}

.conversation-summary-elapsed {
    align-items: center;
    display: flex;
    font-size: 8pt;
}










.conversation-summary-last-message {
    font-size: 8px;
    font-weight: normal;
    margin-left: 10px;
    margin-top: 6px;
}

.conversation-summary-last-read {
    font-size: 10pt;
    font-weight: normal;
}  

.conversation-summary-last-unread {
    font-size: 10pt;
    font-weight: bold;
}
