.calendar-month-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: 100%;
  width: 100%;
}

.calendar-month-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: transparent;
  border-bottom: 1px solid;
}

.calendar-month-days-wrapper {
  display: flex;
  flex-direction: column;
  position: relative; /* remains relative so events overlay can position correctly */
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
}

.calendar-month-days {
  /* Change from flex to grid */
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative; /* optional, for stacking context */
  z-index: 1;
}

.calendar-month-events-overlay {
  height: 100%;
  max-height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.calendar-month-day:last-child {
  border-right: none;
}
