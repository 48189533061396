.grid-item-title-container {
    display: flex;
    flex-direction: row;
    font-size: 10pt;
    font-weight: bold;
    justify-content: left;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    width: 100%;
    z-index: 1;
}