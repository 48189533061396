.profile-button-container {
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    gap: 8px;
    height: 40px;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    width: auto;
  }
  
  .profile-button-title {
    font-size: 9pt;
    font-weight: bold;
    text-wrap: nowrap;
  }