.lookup-editor-options-wrapper {
  margin-top: 12px;
  min-height: 100px;
  max-height: 300px;
  overflow-y: scroll;
  width: 90%;
}

.lookup-editor-option-item {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 11pt;
  font-weight: bold;
}

.lookup-editor-option-text {
  flex-grow: 1;
}

.lookup-editor-remove-container {
  padding-top: 2px;
  width: 14px;
}

.lookup-editor-add-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 10px;
}

.lookup-editor-add-text-container {
  flex-grow: 1;
}

.lookup-editor-add-input {
    border-width: 1px;
    border-style: solid;
    border-radius: 9px;
    font-size: 10pt;
    padding: 12px;
    margin-top: 10px;
    margin-bottom: 18px;
    outline: none;
    width: 100%;
}

.lookup-editor-add-button-container {
  display: flex;
  height: 40px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  width: 36px;
}