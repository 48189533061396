.calendar-nav-container {
    display: flex;
    flex-direction: row;
    padding-top: 2px;
    user-select: none;
    width: auto;
}

.calendar-nav-button {
    cursor: pointer;
    width: 30px;
}