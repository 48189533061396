.month-picker-overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
  }
  
  .month-picker-content {
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    max-height: 100vh;
    min-width: 250px;
    overflow: auto;
    padding: 10px;
    user-select: none;
  }

  .month-picker-header {
    margin-bottom: 10px;
  }

  .month-picker-arrow {
      cursor: pointer;
      width: 30px;
  }

  .month-picker-select {
    background-color: transparent !important;
    border: 0px;
    cursor: pointer;
    font-size: 12pt;
    font-weight: bold;
    outline: none;
}

.month-picker-months {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px; 
    margin-top: 10px;
}

.month-picker-month {
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    justify-content: center;
    padding: 10px;
    padding-bottom: 12px;
    padding-top: 12px;
}