.font-style-picker-container {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    font-size: 9pt;
    padding: 8px;
    position: relative;
    text-align: left;
    width: 100%;
}

.font-style-picker-popup {
    border-width: 1px;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 8px;
    padding-left: 14px;
    padding-right: 14px;
    position: fixed;
    z-index: 10000;
}

.font-style-picker-popup-option {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
}

.font-style-picker-popup-label {
    font-size: 12px;
}