.calendar-storyline-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.calendar-storyline-left {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.calendar-storyline-spacer {
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-top: 1px solid;
}

.calendar-storyline-years-position {
  height: 40px;
}

.calendar-storyline-timeline {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  overflow-x: auto;
  width: 100%;
}

.calendar-storyline-data-wrapper {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  position: relative;
}

.calendar-storyline-gridlines-position {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.calendar-storyline-events-position {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.calendar-storyline-add-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

@media (max-width: 768px) {

    .calendar-storyline-add-button {
        bottom: 70px;
    }

}
