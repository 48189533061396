.calendar-week-event-container {
  border-radius: 7px;
  cursor: pointer;
  left: 70px;
  overflow: hidden;
  pointer-events: auto;
  position: absolute;
  right: 0;
  user-select: none;
  width: 22%;
  z-index: 3;
}

.calendar-week-event-wrapper {
  height: 100%;
  position: relative;
  width: 100%;
}

.calendar-week-event-background {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.calendar-week-event-background-image {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.calendar-week-event-text {
  height: 100%;
  padding: 6px;
  padding-top: 4px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.calendar-week-event-title {
  font-size: 8pt;
  font-weight: bold;
  margin-bottom: 4px;
}

.calendar-week-event-time {
  font-size: 7pt;
  font-weight: normal;
}

.calendar-week-event-resize-handle {
  bottom: 0;
  cursor: grab;
  height: 10px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}

@media (max-width: 768px) {

  .calendar-week-event-title {
    text-wrap: nowrap;
    width: 100%;
  }
  
  .calendar-week-event-time {
    text-wrap: nowrap;
    width: 100%;
  }

}