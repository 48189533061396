.member-thumbs-container {
    align-items: center;
    display: flex;
    position: relative;
    width: auto;
}

.member-thumbs-container.LTR {
    flex-direction: row;
}

.member-thumbs-container.RTL {
    flex-direction: row-reverse;
}

.member-thumb {
    border-style: solid;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    position: absolute; /* Position absolute for overlapping and spreading */
    z-index: 1;
}

.member-thumb:first-child {
    margin-left: 0; /* No negative margin for the first thumb in LTR */
    margin-right: 0; /* No negative margin for the first thumb in RTL */
}

.member-thumb-active {
    background-color: green;
    border-radius: 50%;
    height: 4px;
    margin-left: calc(50% - 2px);
    margin-top: 5px;
    width: 4px;
}
