.calendar-collection-container {
  flex-shrink: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.calendar-collection-corner-container {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 3;
}

/* Header row container (fixed at top, excluding corner cell) */

.calendar-collection-column-header-container {
  border-left: 1px dotted;
  border-bottom: 1px dotted;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.calendar-collection-column-header {
  display: flex;
  font-size: 12pt;
  font-weight: bold;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  z-index: 1;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  overflow-y: scroll; /* Ensure scrolling is still functional */
}
.calendar-collection-column-header::-webkit-scrollbar {
  display: none; /* Webkit browsers (Chrome, Safari, Edge) */
}

.calendar-collection-column-header-cell {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 0 100px;
  justify-content: center;
}

.calendar-collection-row-header-cell {
  align-items: center;
  border-right: 1px dotted;
  border-top: 1px dotted;
  display: flex;
  flex: 0 0 100px;
  justify-content: center;
}

/* First column container (fixed at left, excluding corner cell) */

.calendar-collection-row-header-container {
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden; /* hide overflow, the inner element will scroll */
  z-index: 2;
}

/* The first column will scroll vertically within its container */
.calendar-collection-first-column {
  position: relative;
  width: 100%;
  height: 100%; /* match the container's height, e.g. 500px */
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  overflow-y: scroll; /* Ensure scrolling is still functional */
}
.calendar-collection-first-column::-webkit-scrollbar {
  display: none; /* Webkit browsers (Chrome, Safari, Edge) */
}

/* Main scrollable area (rest of the grid) */
.calendar-collection-main-area {
  bottom: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  z-index: 1;
}

/* Common cell styling */

.calendar-collection-cell {
  flex: 0 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px dotted;
  border-top: 1px dotted;
}

/* Each row in the main area */
.calendar-collection-row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}























/*
.calendar-collection-container {
  background-color: red;
  display: flex;
  flex-direction: row;
  overflow: auto;
  position: relative;
  flex-shrink: 1;
  height: 100%;
  width: 100%;
}
  */

.calendar-collection-hours {
  background-color: green;
  width: 60px;
}

.calendar-collection-right {
  background-color: yellow;
  border-right: 3px solid purple;
  flex-grow: 1;
  overflow: scroll;
}

.calendar-collection-header {
  background-color: orange;
  display: flex;
  height: 50px;
  position: fixed;
}

.calendar-collection-object-header {
  border-right: 2px solid white;
  display: flex;
  width: 200px;
}

.calendar-collection-content {
  background-color: brown;
  height: 1000px;
}






.calendar-collection-all-day {
  margin-left: 20px;
  margin-right: 20px;
  height: auto;
}

.calendar-collection-object-wrapper {
  position: relative;
  margin-top: 20px;
  width: 300px;
}

.calendar-collection-events-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  /* Ensure this is above hour blocks */
  pointer-events: none;
}

.calendar-collection-event-details {
  max-height: 100px;
  overflow: hidden;
}

.calendar-collection-event-title {
  font-size: 9pt;
  font-weight: bold;
  margin-bottom: 4px;
}

.calendar-collection-event-time {
  font-size: 8pt;
  font-weight: normal;
}

.calendar-collection-event-detail {
  font-size: 8pt;
  font-weight: normal;
  margin-top: 6px;
}