.settings-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.settings-menu-wrapper {
    border-right: 1px dotted;
    height: 100%;
    overflow-y: auto;
    padding: 10px;
    width: 30%;
}

.settings-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    position: relative;
}
