.app-settings-color {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.app-settings-color-right {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.app-settings-theme-editor-container {
    flex-grow: 1;
}

.app-settings-theme-editor-title {
  font-size: 12pt;
  font-weight: bold;
  margin-left: 2px;
}

.app-settings-theme-editor-label-title {
  align-items: center;
  display: flex;
  font-size: 11pt;
  font-weight: bold;
  height: 28px;
  padding-bottom: 8px;
}

.app-settings-theme-editor-label-hex {
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-size: 10pt;
  height: 28px;
  padding-bottom: 8px;
}

@media (max-width: 768px) {

  .app-settings-color-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .app-settings-theme-editor-label-hex {
    justify-content: right;
  }

}