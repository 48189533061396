.calendar-collection-hour-container {
  display: flex;
  flex-direction: row;
  height: 70px;
  width: 100%;
  z-index: 1;
}

.calendar-collection-hour-label {
  font-size: 12px;
  margin-top: -7px;
  padding-right: 10px;
  text-align: right;
  user-select: none;
  width: 100%;
}