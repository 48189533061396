.app-wallpaper-edit-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    padding-top: 10px;
    z-index: 500;
}

.app-wallpaper-edit-image-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.app-wallpaper-edit-image,
.app-wallpaper-edit-image-mobile {
    align-items: center;
    border-width: 5px;
    border-style: solid;
    border-radius: 9px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 22px;
    margin-top: 12px;
    overflow: hidden;
}

.app-wallpaper-edit-image {
    height: 150px;
    width: 200px;
}

.app-wallpaper-edit-image-mobile {
    height: 170px;
    width: 240px;
}

.app-wallpaper-edit-image-file {
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    object-position: center;
    width: 100%;
}

.app-wallpaper-edit-title {
    font-size: 12pt;
    font-weight: bold;
    margin-left: 2px;
    margin-bottom: 10px;
    text-align: center;
}