.chat-post-form-document-container {
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-shrink: 1;
    gap: 12px;
    margin-right: auto;
    margin-top: 5px;
    padding: 12px;
    padding-left: 16px;
    padding-right: 20px;
    width: auto;
}