.item-buttons-container {
  align-items: center;
  border: 0px solid;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}