.settings-item-container {
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 8px;
    overflow: hidden;
    padding: 4px;
    padding-left: 8px;
    transition: background-color 0.2s;
}

.settings-item-top {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.settings-item-icon {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 22px;
}

.settings-item-text {
    flex-grow: 1;
    font-size: 10pt;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 5px;
    margin-right: 8px;
    overflow: hidden;
    user-select: none;
    white-space: nowrap;
}

.settings-item-bottom {
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    padding-bottom: 6px;
    padding-left: 22px;
    padding-top: 2px;
    width: 100%;
}