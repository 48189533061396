.notifications-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}

.notifications-container-mobile {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
    width: 100%;
}
.notification-wrapper {
    height: auto; /* Allow height to fit content */
    position: relative; /* Establish positioning context for child elements */
    width: 100%;
}

.notification-container {
    border-bottom: 1px dotted;
    cursor: pointer;
    padding: 20px;
    position: relative; /* No need for absolute positioning here */
    z-index: 2;
}

.notification-mask {
    position: absolute;
    inset: 0; /* Matches the size of the .notification-wrapper */
    opacity: 0.2;
    z-index: 1;
}

.notification-title {
    font-size: 11pt;
    font-weight: bold;
    margin-bottom: 12px;
}

.notification-description {
    font-size: 10pt;
    font-weight: normal;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {

    .notifications-container {
        width: 100%;
    }
  
  }