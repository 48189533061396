.decimal-unit {
    border-width: 1px;
    border-style: solid;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 9pt;
    padding: 8px;
    text-align: center;
    width: 50px;
}

.decimal-unit-select {
    border-width: 0px;
    border-style: solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 9pt;
    outline: none;
    padding: 8px;
    text-align: center;
    width: auto;
}