.link-container {
  border: 1px solid;
  border-radius: 15px;
  cursor: pointer;
  height: auto;
  overflow: hidden;
  width: 100%;
}

.link-image {
  max-height: 250px;
  margin-right: 4px;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.link-title {
  display: flex;
  font-size: 12pt;
  font-weight: bold;
  justify-content: left;
  margin-top: 12px;
  margin-left: 20px;
  margin-right: 20px;
}

.link-description {
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  justify-content: left;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}