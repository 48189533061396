.prompt-modal-container {
    background-color: transparent;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    margin: 4px;
    width: 100%;
}

.prompt-modal-text {
    background-color: transparent;
    border: 0px;
    flex-grow: 1;
    font-size: 11pt;
    height: 100%;
    margin: 3px;
    line-height: 1.4em;
    outline: none;
    overflow: hidden;
    padding: 18px;
    resize: none;
    width: 90%;
}