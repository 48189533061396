.tag-selector-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    height: auto;
    margin-bottom: 4px;
    margin-top: 4px;
    width:100%;
}

.tag-selector-tag {
    align-items: center;
    border-radius: 18px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    height: 36px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
}
