.field-container {
  background-color: transparent;
  border-style: dotted;
  border-width: 1px;
  border-radius: 11px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}