
.checkbox-cell-container {
    border-right-width: 1px;
    border-right-style: dotted;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    cursor: pointer;
    width: auto;
}

.checkbox-cell-checkbox-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 41px;
    justify-content: center;
    width: 100%;
}