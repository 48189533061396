

.condition-list-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 4px;
}

.condition-list-condition {
  font-size: 9pt;
}