.folder-toolbar-container {
    align-content: top;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 6px;
    width: 100%;
    z-index: 3;
}

.folder-toolbar-top {
    display: flex;
    flex-direction: row;
    height: 38px;
    width: 100%;
}

.folder-toolbar-bottom {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
}

.folder-toolbar-share-count-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.folder-toolbar-path {
    align-items: center;
    display: flex;
    font-size: 10pt;
    font-weight: normal;
    padding: 2px;
    padding-left: 4px;
    text-wrap: nowrap;
    width: 100%;
}

.folder-toolbar-item {
    align-items: center;
    display: flex;
}

.folder-toolbar-link {
    border-radius: 5px;
    font-size: 9pt;
    font-weight: bold;
    margin-left: 6px;
    margin-right: 6px;
    padding: 8px;
    padding-left: 12px;
    padding-right: 12px;
}

.folder-toolbar-separator {
    align-items: center;
    display: flex;
}

@media (max-width: 768px) {


}