.member-summary-container {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.member-summary-dot {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 6px;
    width: 36px;
}

.member-summary-user {
    align-items: center;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    user-select: none;
    height: 68px;
}

.member-summary-name {
    position: absolute;
    font-size: 11pt;
    font-weight: normal;
    margin-left: 4px;
}