.tile-designer-container {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  justify-content: space-between;
  height: 100%;
}

.tile-designer-canvas-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 98%;
  width: 100%;
}

.tile-designer-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.tile-designer-properties {
    display: flex;
    flex-direction: column;
    width: 230px;
}

.tile-designer-canvas {
  flex-grow: 1;
  min-height: 200px;
  overflow-y: auto;
  padding-left: 24px;
  padding-right: 10px;
  width: 500px;
}

.tile-designer-elements {
  width: 230px !important;
}