.checklist-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 12px;
  padding: 2px;
  padding-bottom: 16px;
  padding-left: 6px;
  padding-top: 12px;
}

.checklist-header {
  display: flex;
  flex-direction: row;
  height: 30px;
  padding-top: 1px;
}

.checklist-label {
  font-size: 10pt;
  font-weight: normal;
  flex-grow: 1;
  margin-left: 12px;
  user-select: none;
}

.checklist-items {
  margin-bottom: 8px;
  margin-top: 10px;
}

.checklist-item {
  align-items: top;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  user-select: none;
}

.checklist-footer-wrapper {
  display: flex;
  flex-direction: row;
}

.checklist-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8px;
}