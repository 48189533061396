.calendar-collection-hour-block-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
}

.calendar-collection-segment {
  flex-grow: 1;
  position: relative;
  transition: background-color 0.2s ease;
}


.calendar-collection-hour-block-label {
  font-size: 12px;
  margin-top: -7px;
  padding-right: 10px;
  text-align: right;
  user-select: none;
}

.calendar-collection-hour-block-contents {
  flex-grow: 1;
  height: 1px;
}