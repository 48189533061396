.calendar-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.calendar-tag-selector {
  align-items: center;
  display: flex;
  height: 60px;
  min-height: 60px;
  margin-left: 18px;
  overflow-x: auto; /* Enables horizontal scroll when needed */
}