.object-tile-container,
.object-tile-container-mobile {
    border-color: transparent;
    border-radius: 13px;
    cursor: pointer;
    flex-shrink: 0;
    margin-top: 0px;
    position: relative;
    user-select: none;
    overflow: hidden;
}

.object-tile-container-mobile {
    margin-left: 0px;
    margin-right: 0px;
}

.object-tile-container-mask {
    border-radius: 13px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.3;
    pointer-events: none;
}

.object-tile-container-rows {
    padding: 12px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}
