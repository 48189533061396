.note-form-block-container {
    border-radius: 7px;
    margin: 4px;
    padding: 8px;
}

.note-form-block-gallery-wrapper {
    border: 2px solid;
    border-radius: 17px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    width: 440px;
}

.note-form-block-documents-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: auto;
}

.note-form-block-document-wrapper {
    border: 2px solid;
    border-radius: 11px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: auto;
}

.note-form-block-object-wrapper {
    border: 2px solid;
    border-radius: 13px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    width: 440px;
}

.note-form-block-event-wrapper {
    border: 2px solid;
    border-radius: 13px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    width: 440px;
}

.note-form-block-link-wrapper {
    border: 2px solid;
    border-radius: 13px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-left: auto;
    margin-right: auto;
    width: 440px;
}

@media (max-width: 600px) {

    .note-form-block-gallery-wrapper {
        height: 200px;
        width: 100%;
    }

    .note-form-block-object-wrapper {
        margin: 0px;
        margin-top: 20px;
        margin-left: 0px;
        width: 100%;
    }

    .note-form-block-event-wrapper {
        margin: 0px;
        margin-top: 20px;
        margin-left: 0px;
        width: 100%;
    }

    .note-form-block-document-wrapper {
        margin: 0px;
        margin-top: 20px;
        margin-left: 0px;
        width: 100%;
    }

    .note-form-block-link-wrapper {
        margin: 0px;
        margin-top: 20px;
        margin-left: 0px;
        width: 100%;
    }

}