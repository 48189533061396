@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
}

textarea {
  resize: none;
}

.root {
  flex-grow: 1;
}

/* SCROLLBARS */

/* Styling the scrollbar for all elements */
*::-webkit-scrollbar {
  width: 8px;
  /* Adjust the width of the scrollbar */
}

*::-webkit-scrollbar-thumb {
  background-color: #555;
  /* Foreground color of the scrollbar */
  border-radius: 4px;
  /* Rounded corners for the scrollbar thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #444;
  /* Darker shade when hovering over the scrollbar thumb */
}

/* Setting scrollbar styles for Firefox */
* {
  scrollbar-color: #555 transparent;
  /* Foreground (thumb) and background (track) colors */
  scrollbar-width: thin;
  /* Adjust the width ('auto', 'thin', 'none') */
}


