.accordion-section {
    margin-bottom: 2px;
}

.accordion-header {
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    margin-left: 4px;
    margin-right: 4px;
    padding: 10px;
    padding-left: 12px;
    user-select: none;
}

.accordion-header-title {
    font-size: 9pt;
    font-weight: bold;
    margin: 0;
}
.accordion-content-container,
.accordion-content-container.open {
    border-width: 1px;
    border-style: solid;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: 4px;
    margin-right: 4px;
}

.accordion-content-container {
    height: 0;
    /*overflow: hidden;*/
    transition: height 0.1s ease, transform 0.1s ease;
    transform: scaleY(0);
    transform-origin: top;
}

.accordion-content-container.open {
    height: auto;
    transform: scaleY(1);
}

.accordion-content {
    margin: 16px;
    margin-left: 16px;
    margin-right: 20px;
    padding-bottom: 16px;
    /*overflow: hidden;*/
}