.conversations-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-shrink: 1;
    position: relative;
    width: 100%;
}

.conversations-list {
    flex-grow: 1;
    overflow-y: auto;
    padding-top: 22px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

.conversations-add-button {
    bottom: 20px;
    position: absolute;
    right: 20px;
    z-index: 9;
}

@media (max-width: 768px) {

    .conversations-container {
        border-top-width: 0px;
        border-right-width: 0px;
        border-left-width: 0px;
        padding-top: 0px;
        width: 100%;
    }

    .conversations-add-button {
        bottom: 70px;
        position: fixed;
        right: 20px;
        z-index: 9;
    }

    .conversations-list {
        height: calc(100vh - 90px);

        /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
        -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
    }

    .conversations-list::-webkit-scrollbar {
        display: none; /* Hide the scrollbar for WebKit browsers */
    }
    
    /* Hide scrollbar for Firefox */
    .conversations-list {
        scrollbar-width: none; /* Hide the scrollbar in Firefox */
        -ms-overflow-style: none; /* Hide the scrollbar in Internet Explorer 10+ */
    }

}