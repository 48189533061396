.object-checklist-preview-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.object-checklist-preview-content {
  flex-grow: 1;
  padding-top: 13px;
  width: 100%;
}

.object-checklist-preview-header {
  padding: 4px;
  padding-left: 6px;
  padding-bottom: 8px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.object-checklist-preview-label {
  font-size: 10pt;
  margin-left: 12px;
}

.object-checklist-preview-title {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 7px;
  font-size: 12pt;
  font-weight: bold;
  outline: none;
  padding-left: 4px;
}

.object-checklist-preview-wrapper {
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin-top: 5px;
  overflow: hidden;
  padding-bottom: 12px;
}

.object-checklist-preview-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  user-select: none;
}

.object-checklist-preview-item-text {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  font-size: 11pt;
  font-weight: bold;
  height: 100%;
  margin-left: 8px;
  padding: 10px;
}

.object-checklist-preview-item-check-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.object-checklist-preview-item-checkbox {
  margin-left: 6px;
  margin-top: 7px;
  width: auto;
}

.object-checklist-preview-item-remove {
  height: 18px;
  margin-right: 16px;
}

.object-checklist-preview-item-remove-container {
  margin-right: 18px;
}