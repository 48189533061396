.photo-grid-item-container {
  align-items: center;
  border: 0px solid;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.photo-grid-item-image-wrapper {
  align-items: center;
  border-radius: 11px;
  display: flex; 
  flex-direction: row;
  max-height: 200px;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.photo-grid-item-image {
  height: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
  width: 100%;
}
