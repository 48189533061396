.feature-modal-overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.feature-modal-content {
  border-radius: 13px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  max-height: 80vw; /* Adjust as needed */
  min-width: 400px; /* Adjust as needed */
  max-width: 80vw; /* Adjust as needed */
  overflow: auto;
  padding: 10px;
  user-select: none;
  transform: scale(0.9); /* Initial scale for animation */
}

.feature-modal-body {
  padding-bottom: 12px;
  padding-top: 0px;
}

.feature-modal-save-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  font-weight: bold;
  justify-content: center;
  padding: 8px;
  width: 100%;
}