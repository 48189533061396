.toolbar-button-container {
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 10px;
    width: auto;
  }
  
  .toolbar-button-title {
    font-size: 9pt;
    font-weight: bold;
    margin-right: 4px;
    text-wrap: nowrap;
  }