.trash-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    height: 100%;
    overflow-y: hidden;
    position: relative;
    width: 100%;
  }

  .trash-grid-container {
    display: flex;
    gap: 16px;
    height: 100%;
    overflow-y: auto;
  }
  
  .trash-grid-container-mobile {
    display: flex;
    gap: 16px;
    height: 100%;
    margin-bottom: 50px;
    overflow-y: auto;
    padding: 16px;
  }