.profile-search-item-container {
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: left;
    margin-left: 10px;
    margin-right: 10px;
    padding: 12pt;
    user-select: none;
}

.profile-search-item-image-wrapper {
    align-items: center;
    display: flex;
    width: auto;
}

.profile-search-item-text-wrapper {
    align-items: center;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
}