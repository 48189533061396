.month-preview-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.month-preview-input {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 7px;
  font-size: 11pt;
  font-weight: bold;
  margin-right: 20px;
  margin-top: 10px;
  outline: none;
  padding: 12px;
}

.month-preview-label {
  font-size: 10pt;
  margin-left: 12px;
}