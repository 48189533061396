.model-add-autogen-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    justify-content: left;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 40px;
    width: auto;
}

.model-add-autopop-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    justify-content: left;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 14px;
    width: auto;
}