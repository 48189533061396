.chat-container {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
}

.chat-container-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 50px);
}

.chat-left {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: 100%;
  width: 100%;
}

.chat-right {
  border-left: 1px solid;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 30%;
}

@media screen and (max-width: 768px) {

  .chat-right {
    display: none;
  }

}