.menu-group-section {
    margin-bottom: 20px;
}

.menu-group-header {
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-left: 20px;
    margin-right: 21px;
    user-select: none;
}

.menu-group-header-left {
    display: flex;
    gap: 14px;
}

.menu-group-header-chevron {
    margin-top: 2px;
    opacity: 0.5;
}

.menu-group-content-container {
    overflow: hidden; /* Prevent content overflow during animations */
    transition: height 0.2s ease; /* Smooth height transition */
    height: 0; /* Collapsed by default */
}

.menu-group-content-container.open {
    height: auto; /* Dynamically set via inline styles */
}

.menu-group-content {
    padding-top: 6px;
}
