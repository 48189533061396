/* BUTTON */
.color-selector-button {
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 20px;
  margin-right: 18px;
  width: 20px;
}

/* POPUP */
.color-selector-popup {
  position: absolute;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
