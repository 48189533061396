.add-block-button-container {
    align-items: center;
    border: 1px dotted;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    justify-content: left;
    margin-right: 4px;
}

.note-form-plus-button {
    align-items: center;
    border: 1px dotted;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: center;
    width: 40px;
}