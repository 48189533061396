.item-panel-row-container {
    height: auto;
    width: 100%;
}

.item-panel-row-title {
    align-items: center;
    display: flex;
    font-size: 12pt;
    font-weight: bold;
    gap: 4px;
    margin-left: 20px;
    margin-bottom: 6px;
  }

  .item-panel-row-content {
    height: auto;
    overflow-x: auto;
    width: 100%;
  }