.calendar-day-hour-block-container {
  display: flex;
  flex-direction: row;
  height: 70px;
  z-index: 1;
}

.calendar-day-hour-block-label {
  border-right-width: 1px;
  border-right-style: solid;
  font-size: 12px;
  margin-top: -7px;
  padding-right: 10px;
  text-align: right;
  user-select: none;
}

.calendar-day-hour-block-contents {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  flex-grow: 1;
  height: 1px;
}