.field-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8px;
}

.field-header-label {
  font-size: 10pt;
  margin-left: 12px;
  user-select: none;
}