.comments-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  max-height: 400px;
  overflow: scroll;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-top: 12px;
  position: relative;
}

.comments-header {
  display: flex;
  flex-direction: row;
  height: 30px;
  padding-top: 1px;
  padding-right: 8px;
}

.comments-title {
  font-size: 10pt;
  font-weight: normal;
  flex-grow: 1;
  margin-left: 12px;
  user-select: none;
}

.comments-add-container {
  display: flex;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 14px;
  padding-top: 14px;
  width: 100%;
}

.comments-add-input {
  box-sizing: border-box;
  font-size: 10pt;
  font-weight: normal;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  flex-grow: 1;
  outline: none;
  padding: 12px;
}

.comments-add-buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-bottom: 10px;
  padding-right: 20px;
}

.comments-add-cancel-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 9pt;
  font-weight: bold;
  padding: 8px;
  width: 100px;
}

.comments-add-comment-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 7px;
  font-size: 9pt;
  font-weight: bold;
  padding: 8px;
  width: 100px;
}

.comments-list {
  height: auto;
  margin-left: 0px;
  margin-right: 18px;
  margin-top: 12px;
  padding-bottom: 8px;
}

.comment-container {
  display: flex;
  flex-direction: row;
  margin-left: 18pt;
  padding-bottom: 12pt;
}

.comment-right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  padding-left: 16px;
}

.comment-name {
  font-size: 9pt;
  font-weight: bold;
}

.comment-text {
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-size: 10pt;
  line-height: 1.3em;
  padding-right: 12px;
}

.comment-show-reply-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 9pt;
  font-weight: bold;
  width: auto;
}

.comment-show-reply-panel {
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  padding: 8px;
  position: absolute;
  z-index: 1000;
}

.comment-show-replying-to {
  font-size: 10pt;
}

.comment-show-replies-button {
  border-radius: 11px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  justify-content: center;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  width: 200px;
}

.comment-reply-panel {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.comments-reply-input {
  box-sizing: border-box;
  font-size: 10pt;
  font-weight: normal;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  flex-grow: 1;
  outline: none;
  padding: 12px;
}

.comments-reply-buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
}