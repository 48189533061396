.password-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.password-input {
  background-color: transparent;
  border: 1px dotted transparent;
  border-radius: 5px;
  font-size: 12pt;
  font-weight: normal;
  margin-top: 6px;
  outline: none;
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  flex-grow: 1;
}

.password-icon {
  cursor: pointer;
  margin-right: 24px;
  margin-top: 14px;
}

.password-label {
  font-size: 10pt;
  margin-left: 12px;
  user-select: none;
}

.password-showhide {
  cursor: pointer; /* Indicate the icon is clickable */
  width: 18px; /* Adjust size as necessary */
  height: auto; /* Maintain aspect ratio */
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 20px;
}

.password-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}

.password-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8px;
}