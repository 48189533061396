.calendar-day-all-day-event {
  align-items: center;
  border-radius: 9px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 30px;
  margin-bottom: 2px;
  padding-left: 10px;
  position: absolute;
  right: 0;
  user-select: none;
  width: 100%;
  z-index: 3;
  overflow: hidden;
  /* Hide overflow */
  position: relative;
  /* Needed for the fade div */
}

.calendar-day-all-day-event-title {
  font-size: 8pt;
  font-weight: bold;
}

.calendar-day-all-day-event-date {
  font-size: 8pt;
  font-weight: normal;
}