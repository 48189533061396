.members-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.members-left {
    border-right-width: 1px;
    border-right-style: solid;
    border-top-width: 0px;
    border-top-style: solid;
    display: flex;
    flex-direction: column;
    position: relative;
}

.members-right {
    width: 50%;
}

.members-list {
    overflow-y: auto;
    padding-top: 22px;
    padding-left: 10px;
    padding-right: 10px;
    flex-grow: 1;
    width: 100%;
}

.members-add-button,
.members-add-button-mobile {
    position: absolute;
    right: 20px;
    z-index: 9;
}

.members-add-button {
    bottom: 20px;
}

.members-add-button-mobile {
    bottom: 70px;
}

.members-user-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.members-user-left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 6px;
    width: 36px;
}

.members-user-center {
    align-items: center;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    user-select: none;
    height: 68px;
}

.members-user-name {
    position: absolute;
    font-size: 12pt;
    font-weight: bold;
    margin-left: 4px;
}

.members-grid-container {
    display: flex;
    gap: 16px;
    height: 100%;
    overflow-y: auto;
    padding: 16px;
  }
  
  .members-grid-container-mobile {
    display: flex;
    gap: 16px;
    height: 100%;
    margin-bottom: 50px;
    overflow-y: auto;
    padding: 16px;
  }
  
  .members-grid-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

@media (max-width: 768px) {

    .members-add-button {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 1000;
    }

    .members-left {
        border-top-width: 0px;
        border-right-width: 0px;
        border-left-width: 0px;
        padding-top: 0px;
        width: 100%;
    }

    .members-add-button {
        position: fixed; /* Changed from absolute to fixed */
        bottom: 20px;
        right: 20px;
        z-index: 1000; /* Ensure it's above other content */
    }

    .members-list {
        height: calc(100vh - 90px);

        /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
        -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
    }

    .members-list::-webkit-scrollbar {
        display: none; /* Hide the scrollbar for WebKit browsers */
    }
    
    /* Hide scrollbar for Firefox */
    .members-list {
        scrollbar-width: none; /* Hide the scrollbar in Firefox */
        -ms-overflow-style: none; /* Hide the scrollbar in Internet Explorer 10+ */
    }

}