.room-grid-item-container {
  align-items: center;
  border: 0px solid;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  position: relative;
  width: 100%;
}
