.profile-dot-container {
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: auto;
    margin-right: 3px;
    overflow: hidden;
    transition: background-color 0.2s;
    user-select: none;
    width: auto;
}

.profile-dot-image {
    height: 70px;
    min-height: 50%;
    object-fit: cover;
    width: 70px;
}

.profile-dot-letters {
    align-items: center;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    font-size: var(--dot-font-size);
    font-weight: bold;
    justify-content: center;
}