.object-summary-row-container {
  align-items: center;
  border-width: 0px;
  display: flex;
  gap: 4px;
  /* Ensure all children (blocks) are evenly spaced */
  justify-content: space-between;
}

.dragging {
  opacity: 0.5;
}