.models-container {
    display: flex;
    flex-direction: column;
    height: calc(100% - 24px);
    width: 100%;
}

.models-scroller {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 10px;
    overflow-y: auto;
    padding-top: 10px;
}

.models-item-container {
    align-items: center;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 2px;
    overflow: hidden;
    padding: 3px;
    padding-left: 8px;
    transition: background-color 0.2s;
}

.models-item-top {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.models-item-icon {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 22px;
}

.models-item-text {
    flex-grow: 1;
    font-size: 10pt;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 5px;
    margin-right: 8px;
    overflow: hidden;
    user-select: none;
    white-space: nowrap;
}

.models-item-bottom {
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    padding-bottom: 6px;
    padding-left: 22px;
    padding-top: 2px;
    width: 100%;
}