.menuitems-container {
    border-radius: 11px;
    display: flex;
    flex-direction: column; 
    height: 100%;
    justify-content: space-between;
    overflow-y: auto;
    padding-top: 0px;
    transition: width 0.1s ease-in;
    width: 100%;
}

.menuitems-container-mobile {
    border-radius: 11px;
    box-shadow: rgba(0,0,0,0.5) 0 2px 8px;
    flex-direction: column; 
    height: 100%;
    justify-content: space-between;
    overflow-y: auto;
    padding-top: 0px;
    transition: width 0.1s ease-in;
    width: 100%;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    overflow-y: scroll; /* Ensure scrolling is still functional */
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari, Edge) */
.menuitems-container-mobile::-webkit-scrollbar {
    display: none;
}

.menuitems-list {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 80px;
    padding-top: 10px;
    width: 100%;
}

.menu-items-highlighted {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 20px;
}

.menu-item-text {
    font-size: 11pt;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 5px;
    margin-left: 8px;
    margin-right: 8px;
    overflow: hidden;
    user-select: none;
    white-space: nowrap;
    width: 160px;
}

.menu-items-buttons {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 70px;
    justify-content: center;
    width: 100%;
}

@media (max-width: 768px) {

    .menuitems-list {
        flex-shrink: 1;
        height: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 10px;
        width: 100%;

        /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
        -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
    }

    .menuitems-list::-webkit-scrollbar {
        display: none; /* Hide the scrollbar for WebKit browsers */
    }
    
    /* Hide scrollbar for Firefox */
    .menuitems-list {
        scrollbar-width: none; /* Hide the scrollbar in Firefox */
        -ms-overflow-style: none; /* Hide the scrollbar in Internet Explorer 10+ */
    }

}