.toolbar-container {
    align-content: top;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 38px;
    min-height: 38px;
    justify-content: space-between;
    margin-bottom: 6px;
    width: 100%;
    z-index: 3;
}

.toolbar-left {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 100%;
    justify-content: left;
    padding-left: 10px;
    padding-top: 2px;
    width: 100%;
}

.toolbar-right {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: left;
    padding-right: 16px;
    padding-top: 2px;
    width: auto;
}