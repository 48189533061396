.room-container {
  display: flex;
  flex-shrink: 1;
  gap: 8px;
  height: 100%;
  overflow: none;
  width: 100%;
}

.room-mask {
  border-radius: 17px;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.room-left {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 8px;
  position: relative;
  width: 50%;
}

.room-right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 8px;
  overflow-y: auto;
  position: relative;
  width: 50%;
}

.room-content {
  height: 100%;
  overflow-y: auto;
  padding-top: 8px;
  position: relative;
  width: 100%;
  z-index: 2;
}

.room-chat-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.room-chat-header {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.room-chat-participants {
  width: 150px;
}

.room-chat-posts {
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
}

.room-chat-form {
  height: auto;
  width: 100%;
}