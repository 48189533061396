.field-selector-header-background {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.field-selector-header-input {
    border-width: 1px;
    border-style: dotted;
    border-radius: 7px;
    font-size: 9pt;
    font-weight: normal;
    height: auto;
    margin-bottom: 10px;
    padding: 10px;
    outline: none;
    width: 100%;
}

.field-selector-header-close {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 10%;
}