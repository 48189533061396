.note-form-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.note-form-inner-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);
    max-width: 740px;
    width: 90%;
}

.note-form-title-wrapper {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    gap: 10px;
    width: 100%;
}

.note-form-title-left {
    align-items: center;
    display: flex;
}

.note-form-title-center {
    flex-grow: 1;
    flex-shrink: 1;
}

.note-form-title-right {
    align-items: center;
    display: flex;
    width: auto;
}

.note-form-add-panel-arrow {
    align-items: center;
    display: flex;
    margin-right: 10px;
}

.note-content {
    display: flex;
    flex-direction: row;
}

.note-form-blocks {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    height: calc(100vh - 140px);
    overflow-y: auto;
    padding-bottom: 200px;
    width: 100%;
}

.note-form-add-panel-container {
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    max-height: 50px;
    justify-content: left;
    margin-left: 4px;
    margin-right: 4px;
    padding: 6px;
    transition: opacity 0.4s ease-in-out;
}

@media (max-width: 900px) {

    .note-form-inner-container {
        width: 90%;
    }

    .note-form-button {
        font-size: 10pt;
        height: 32px;
    }

}