.calendar-menu-outer-container {
  position: relative;
}

.calendar-menu-container {
  align-items: top;
  justify-content: right;
  border: 1px solid transparent;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 9pt;
  font-weight: bold;
  justify-content: center;
  margin-right: 14px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
