.context-menu-option-container {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  gap: 8px;
  margin-left: 8px;
  margin-right: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 26px;
  text-align: left;
  text-wrap: nowrap;
  user-select: none;
}

.context-menu-option-icon {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 18px;
}