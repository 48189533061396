.studio-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.designer-container {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    height: 100%;
    width: 100%;
  }
  
  .designer-models-wrapper {
    border-right-width: 1px;
    border-right-style: dotted;
    height: 100%;
    overflow-y: auto;
    padding: 10px;
    width: 33% !important;
  }
  
  .designer-canvas-wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 10px;
    padding-left: 6px;
    padding-right: 2px;
    width: 33% !important;
  }
  
  .designer-selector-wrapper {
    border-left-width: 1px;
    border-left-style: dotted;
    height: 100%;
    overflow-y: auto;
    padding: 10px;
    width: 33% !important;
  }
  
  .designer-buttons {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .designer-canvas {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 200px;
    overflow-y: auto;
    padding: 2px;
    padding-top: 10px;
    position: relative;
  
  }
  
  .designer-field {
    align-items: center;
    cursor: move;
    display: flex;
    gap: 4px;
    height: auto;
    margin-bottom: 4px;
    padding-left: 12px;
    padding-right: 8px;
    width: 100%;
  }
  
  .designer-field-wrapper {
    align-items: center;
    border: 1px dotted;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 2px;
    height: 100%;
    justify-content: left;
    padding: 10px;
    padding-left: 12px;
    padding-bottom: 12px;
  }
  
  .designer-field-title {
    display: flex;
    font-size: 9pt;
    font-weight: bold;
    justify-content: left;
    padding: 2px;
    width: 100%;
  }
  
  .designer-field-type {
    display: flex;
    font-size: 9pt;
    justify-content: left;
    padding: 2px;
    width: 100%;
  }
  
  .designer-settings {
    border-left-width: 1px;
    border-left-style: dotted;
    display: flex;
    flex-direction: column;
    gap: 18px;
    height: 100%;
    padding: 10px;
    width: 33% !important;
  }
  
  .designer-label-container {
    font-size: 9pt;
    margin-top: 12px;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 10px;
  }
  
  .designer-input-container {
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    font-size: 9pt;
    outline: none;
    padding: 12px;
    width: 100%;
  }
  
  .designer-textarea-container {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    font-size: 9pt;
    height: 72px;
    outline: none;
    padding: 12px;
    resize: none;
    width: 100%;
  }