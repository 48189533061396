.chat-user-container {
    display: flex;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    user-select: none;
    height: 40px;
}

.chat-user-dot {
    height: auto;
    margin-left: 12px;
    margin-top: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-user-name {
    font-size: 9pt;
    font-weight: normal;
    height: auto;
    margin-left: 12px;
    margin-top: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}