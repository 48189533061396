.tile-canvas-row-container {
  align-items: center;
  border-width: 0px;
  display: flex;
  gap: 4px;
  min-height: 20px;
  padding: 2px;
  /* Ensure all children (blocks) are evenly spaced */
  justify-content: space-between;
}

.dragging {
  opacity: 0.5;
}