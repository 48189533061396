.calendar-schedule-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: 100%;
  position: relative;
  width: 100%;
}

.calendar-schedule-event {
  border-radius: 9px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 12px;
  padding-top: 9px;
  pointer-events: auto;
  position: absolute;
  right: 0;
  user-select: none;
  width: calc(100% - 40px);
  z-index: 3;
  overflow: hidden;
  position: relative;
}

.calendar-schedule-day {
  margin-bottom: 18px;
  margin-left: 20px;
}

.calendar-schedule-date {
  font-size: 11pt;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}