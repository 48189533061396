

.calendar-week-hour-block-container {
  display: flex;
  flex-direction: row;
  height: 70px;
}

.calendar-week-hour-label {
  border-right: 1px solid;
  font-size: 12px;
  margin-top: -7px;
  padding-right: 10px;
  text-align: right;
  user-select: none;
  width: 60px;
}