.home-grid-container {
  display: flex;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
  width: 100%;
}

.home-scroll-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  width: 100%;
}

.home-item {
  align-items: flex-end;
  border-radius: 17px;
  display: flex;
  flex: 0 0 auto;
  height: 90vh;
  justify-content: center;
  scroll-snap-align: start;
  width: 520px;
}

.home-item-wrapper {
  align-items: flex-end;
  border-radius: 17px;
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  justify-content: center;
  scroll-snap-align: start;
  width: 460px;
}

.home-item-buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 450px;
  padding-top: 10px;
  width: 60px;

}