.object-grid-item-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-bottom: 0px;
    flex-direction: column;
    padding-bottom: 14px;
    position: relative;
    width: 100%;
}

.object-grid-item-mask {
  border-radius: 11px;
  height: 100%;
  overflow: auto;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.object-grid-item-image-wrapper {
    align-items: center;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    display: flex; 
    flex-direction: row;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    z-index: 1;
}

.object-grid-item-image {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.object-grid-item-privacy-wrapper {
    padding: 6px;
    padding-left: 14px;
    padding-top: 8px;
    width: 100%;
    z-index: 4;
}

.object-grid-item-text-fields-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
    padding-top: 4px;
    width: 100%;
    z-index: 1;
}

.object-grid-item-title {
    display: flex;
    flex-direction: row;
    font-size: 10pt;
    font-weight: bold;
    justify-content: left;
    width: 100%;
}

.object-grid-item-field {
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    justify-content: left;
    width: 100%;
}

.object-grid-item-checklist {
    width: 100%;
}

.object-grid-item-checklist-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    gap: 8px;
    justify-content: left;
    padding-bottom: 2px;
    padding-top: 2px;
    width: 100%;
}
