.studio-header {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    height: 24px;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 21px;
    user-select: none;
}