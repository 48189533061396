.tile-list-elements-container {
    border-right-width: 1px;
    border-right-style: dotted;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 98%; 
    max-width: 230px;
    min-width: 230px;
    transition: width 0.1s ease-in;
    user-select: none;
}

.tile-list-elements-scroller {
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    padding-top: 20px;
}