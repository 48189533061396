.rate-button-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
  height: 100%;
  width: 100%;
}

.rate-button-leading-text {
  font-size: 9pt;
}

.rate-button-trailing-text {
  font-size: 9pt;
}