.object-add-container {
    height: calc(100% - 30px);
    position: relative;
}

.object-add-scroller {
    height: calc(100% - 80px);
    margin-bottom: 50px;
    overflow-y: auto;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 16px;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    transition: height 0.3s ease;
    /* Smooth transition for height */
    z-index: 1;
}

.object-add-tag-selector-wrapper {
    margin-left: 20px;
    margin-right: 12px;
    margin-top: 4px;
    padding-bottom: 8px;
    padding-top: 12px;
    z-index: 1;
}

.object-add-buttons {
    align-items: center;
    display: flex;
    gap: 30px;
    height: 70px;
    justify-content: center;
    width: 100%;
}