.event-feed-item-container {
  align-items: center;
  border: 0px solid;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.event-feed-item-icon-container {
  align-items: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-bottom: 150px;
  opacity: 0.6;
  width: 100%;
}

.event-feed-item-header {
  align-items: center;
  top: 0px;
  display: flex;
  gap: 10px;
  height: auto;
  justify-content: left;
  padding: 12px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.event-feed-item-header-name {
  font-size: 10pt;
  font-weight: bold;
  margin-left: 4px;
}

.event-feed-item-header-type {
  align-items: center;
  display: flex;
  font-size: 9pt;
  font-weight: normal;
  gap: 4px;
  margin-right: 6px;
}

.event-feed-item-footer {
  align-items: center;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
  padding: 20px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.event-feed-item-title {
  font-size: 13pt;
  font-weight: bold;
}

.event-feed-item-dates {
  font-size: 10pt;
  font-weight: normal;
}

.event-feed-item-location {
  font-size: 11pt;
  font-weight: bold;
}

.event-feed-item-button {
  align-items: center;
  border-radius: 11px;
  display: flex;
  font-size: 12pt;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  margin-top: 12px;
  width: 80%
}

.event-feed-item-image-container {
  align-items: center;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 250px;
  overflow: hidden;
  position: relative;
  width: 100%; /* Ensure the container takes full width */
}

.event-feed-item-image {
  height: 100%;
  width: 100%;
  object-fit: cover; /* Ensures the image covers the entire container */
  object-position: center; /* Centers the image within the container */
  position: absolute; /* Ensures the image is positioned relative to the container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the image perfectly */
}