.tile-canvas-block-container {
  border-width: 1px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  padding: 2px;
  min-height: 20px;
  cursor: pointer;
}

.tile-canvas-block-empty {
  margin: auto;
  width: auto;
}

.tile-canvas-block-element {
  align-items: center;
  border-width: 1px;
  display: flex;
  justify-content: center;
  width: auto !important;
  height: auto !important;
}