.collection-permissions-container {
    height: auto;
    padding-bottom: 20px;
}

.collection-permissions-model-header {
    display: flex;
    flex-direction: row;
    gap: 14px;
    margin-bottom: 14px;
}

.collection-permissions-model-chevron {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    width: auto;
}

.collection-permissions-model-title {
    cursor: pointer;
    font-size: 14pt;
    font-weight: bold;
}

.collection-permissions-role-container {
    border: 1px solid;
    border-radius: 11px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    height: auto;
    margin-left: 10px;
    margin-right: 0px;
    padding-top: 16px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 6px;
}

.collection-permissions-role-title {
    font-size: 11pt;
    font-weight: bold;
    margin-bottom: 14px;
}

.collection-permissions-creator-only-container {
    cursor: pointer;
    display: flex;
    margin-bottom: 6px;
    margin-left: 30px;
}

.collection-permissions-model-contents {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.collection-permissions-creator-only-checkbox {
    padding-top: 2px;
    width: 30px;
}

.collection-permissions-creator-only-label {
    font-size: 10pt;
    font-weight: normal;
    line-height: 1.5em;
    padding-left: 2px;
}