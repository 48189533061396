.calendar-storyline-event-container {
  position: absolute;
}

.calendar-storyline-event-box {
  border: 2px solid;
  border-bottom: 1px dotted;
  border-top: 0px;
}

.calendar-storyline-event-box-inner {
  height: 100%;
  width: 100%;
}

.calendar-storyline-event-title {
  cursor: pointer;
  font-size: 9pt;
  margin-top: 8px;
}
