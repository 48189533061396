.status-message-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 10pt;
    justify-content: center;
    opacity: 1;
    padding: 12px;
    transition: opacity 0.5s ease-out;
    width: auto;
}

.status-message-container.fade-out {
    opacity: 0;
}