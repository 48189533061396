.menu-header-container {
    align-items: center;
    justify-content: left;
    display: flex;
    height: 40px;
    flex-direction: row;
    padding-left: 14px;
    width: 100%;
}

.menu-header-dock-up-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: left;
    margin-left: 2px;
}

@media (max-width: 768px) {

    .menu-header-container {
        display: none;
    }

}