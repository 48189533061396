.file-grid-item-container {
  align-items: center;
  border: 0px solid;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;
  position: relative;
  width: 100%;
}

.file-grid-item-image-wrapper {
  align-items: center;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  display: flex; 
  flex-direction: row;
  max-height: 200px;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.file-grid-item-image {
  height: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
  width: 100%;
}

.file-grid-item-icon-container {
  align-items: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  display: flex;
  height: 121px;
  justify-content: center;
  padding: 30px;
  opacity: 0.6;
  width: 100%;
}
