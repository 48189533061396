.chat-toolbar-container {
    align-content: top;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
}

.chat-toolbar-title {
    font-size: 16pt;
    font-weight: bold;
    margin-left: 20px;
}

.chat-toolbar-buttons {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.chat-toolbar-button {
    align-content: center;
    background-color: transparent;
    border-radius: 7px;
    margin-left: 11px;
    height: 30px;
    text-align: center;
    width: 30px;
    user-select: none;
}

.chat-toolbar-button-image {
    width: 18px;
}

.chat-toolbar-left {
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 16pt;
    font-weight: bold;
    gap: 6px;
    height: 30px;
    justify-content: left;
    padding-left: 18px;
    text-wrap: nowrap;
    user-select: none;
    width: 70%;
}

.chat-toolbar-right {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: right;
    padding-right: 20px;
    width: 30%;
}