.date-field-outer-container {
    position: relative;
}

.date-field-cell-container {
    border-right-width: 1px;
    border-right-style: dotted;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    cursor: pointer;
    width: auto;
}

.date-field-cell-input {
    border: 0;
    background-color: transparent;
    font-size: 10pt;
    font-weight: normal;
    height: 100%;
    outline: none;
    padding: 11px;
    width: auto;
}