.profile-stat-container {
    width: 80px;
}

.profile-stat-value {
    display: flex;
    font-size: 14pt;
    font-weight: bold;
    justify-content: center;
}

.profile-stat-label {
    display: flex;
    font-size: 10pt;
    justify-content: center;
}