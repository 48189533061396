.collection-permission-container {
  border-top: 1px dotted;
  display: flex;
  padding-bottom: 9px;
  padding-left: 4px;
  padding-top: 10px;
  width: 100%;
}

.collection-permission-title {
  cursor: pointer;
  font-size: 11pt;
  font-weight: bold;
  margin-top: 2px;
  width: 90px;
}

.collection-permission-contents {
  display: flex;
  flex-grow: 1;
}

.collection-permission-checkbox {
  padding-top: 3px;
  width: 30px;
}