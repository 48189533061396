.user-selector-container {
  align-items: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  width: 100%;
}

.user-selector-users {
  overflow-y: auto;
  height: 85%;
  width: 100%;
}

.user-selector-checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 12px;
}

.user-selector-user {
  border: 1px solid;
  border-radius: 11px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding-left: 2px;
  width: 100%;
}

.user-selector-summary-wrapper {
  flex-grow: 1;
  padding-left: 10px;
}

.user-selector-footer {
  display: flex;
  height: auto;
  justify-content: center;
  margin-bottom: 12px;
}

.user-selector-done-button {
  border-radius: 7px;
  display: flex;
  font-size: 10pt;
  justify-content: center;
  margin-top: 20px;
  margin-left: 10px;
  padding: 12px;
  width: 100px;
}
