.participants-bar-photos-container {
  align-items: center;
  display: flex;
  gap: 4px;
  height: 100%;
  justify-content: center;
  border-radius: 9px;
  width: 100%;
  padding: 4px;
}

.participants-bar-photo {
  border-radius: 7px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
}

.participants-bar-extra {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 9px;
  text-align: center;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
}