.field-preview-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 6px;
}

.field-preview-wrapper {
    transform-origin: top left;
    width: 100%;
}

.field-preview-title {
    font-size: 10pt;
    font-weight: bold;
    margin-bottom: 4px;
    margin-left: 2px;
    margin-top: 2px;
}

.field-preview-description {
    font-size: 10pt;
    font-weight: normal;
    height: auto;
    margin-top: 12px;
    margin-bottom: 6px;
    margin-left: 2px;
}