.barcode-button {
  border-width: 1px;
  border-style: solid;
  border-radius: 9px;
  font-size: 12pt;
  height: 44px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}

.barcode-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.barcode-label {
  font-size: 10pt;
  margin-left: 12px;
  user-select: none;
}

.barcode-text {
  font-size: 10pt;
  margin-bottom: 14px;
  text-align: center;
}

.barcode-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8px;
}