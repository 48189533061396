.calendar-schedule-event-container {
    border: 1px solid;
    border-radius: 9px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    left: 70px;
    padding: 10px;
    padding-top: 9px;
    pointer-events: auto;
    position: absolute;
    right: 0;
    user-select: none;
    width: 80%;
    z-index: 3;
    overflow: hidden; /* Hide overflow */
    position: relative; /* Needed for the fade div */
  }

  .calendar-schedule-event-title {
    font-size: 10pt;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .calendar-schedule-event-time {
    font-size: 8pt;
    font-weight: bold;
  }

  .calendar-schedule-event-details {
    max-height: 100px; /* Adjust based on your requirement */
    overflow: hidden;
  }

  .calendar-schedule-event-detail {
    font-size: 9pt;
    font-weight: normal;
    margin-top: 6px;
  }