.form-container {
    height: calc(100% - 30px);
    position: relative;
}

.form-container-mobile {
    display: flex;
    flex-direction: column;
    margin: 0px;
    height: calc(100vh - 50px);
    overflow-y: auto;
    position: relative;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    width: 100%;
}

.form-scroller {
    height: calc(100% - 80px);
    margin-bottom: 50px;
    overflow-y: auto;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 16px;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    transition: height 0.3s ease;
    /* Smooth transition for height */
    z-index: 1;
}

.form-tag-selector-wrapper {
    margin-left: 20px;
    margin-right: 12px;
    margin-top: 4px;
    padding-bottom: 8px;
    padding-top: 12px;
    z-index: 1;
}

.form-buttons-left {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 12px;
    justify-content: flex-start;
}

.form-buttons-right {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 12px;
    justify-content: flex-end;
}

/* Scrollbar Classes*/
.form-container::-webkit-scrollbar,
.form-container-mobile::-webkit-scrollbar {
    width: 8px;
}

.form-container::-webkit-scrollbar-thumb,
.form-container-mobile::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 4px;
}

.form-container::-webkit-scrollbar-thumb:hover,
.form-containermobile::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
}

@media (max-width: 768px) {

    .form-buttons {
        bottom: 70px;
    }

}