.objects-container {
    display: flex;
    flex-direction: column;
    position: relative;
    transition: width 0.3s ease;
}
.objects-container-mobile {
    border-right-width: 0px;
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    position: relative;
    width: 100%;
}

.objects-tag-selector {
    margin-left: 18px;
    margin-right: 18px;
    margin-top: 12px;
}

.objects-add-button,
.objects-add-button-mobile {
    position: absolute;
    right: 20px;
    z-index: 9;
}

.objects-add-button {
    bottom: 20px;
}

.objects-add-button-mobile {
    bottom: 70px;
}

.objects-list {
    flex-grow: 1;
    overflow-y: auto;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}
.objects-list-mobile {
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 16px;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
    scrollbar-width: none; /* Hide the scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar in Internet Explorer 10+ */
}