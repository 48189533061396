.event-dates-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.event-dates-row {
    display: flex;
    flex-direction: row;
}

.event-dates-to {
    align-items: center;
    font-size: 11pt;
    display: flex;
    padding-left: 12px;
}