.country-value {
    background-color: transparent;
    border: 1px dotted transparent;
    border-radius: 5px;
    font-size: 11pt;
    font-weight: normal;
    line-height: 1.5em;
    margin-top: 6px;
    outline: none;
    padding: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
  }