.message-outer-wrapper {
    flex-direction: column-reverse;
    margin-bottom: 12px;
}

.message-container-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 6px;
    padding-left: 24px;
    width: 100%;
}

.message-container-right {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 6px;
    padding-right: 24px;
    width: 100%;
}

.message-wrapper {
    border-radius: 19px;
    max-width: 80%;
    width: auto;  /* Allows the wrapper to expand as needed */
    word-wrap: break-word; /* Ensures long words or links wrap correctly */
    display: inline-block; /* Ensures wrapper sizes to its content */
}

.message-text {
    font-size: 11pt;
    font-weight: normal;
    padding: 12px;
    padding-bottom: 8px;
    padding-top: 8px;
    line-height: 1.5em;
    white-space: pre-wrap; /* Handles whitespace and line breaks correctly */
    word-wrap: break-word; /* Ensures words wrap within the bubble */
    display: inline-block; /* Allows the text container to size correctly */
}

.message-header-left,
.message-header-right {
    display: flex;
    font-size: 8pt;
    margin-left: 34px;
    margin-right: 34px;
}

.message-header-left {
    justify-content: left;
}

.message-header-right {
    justify-content: right;
}

.message-elapsed-left,
.message-elapsed-right {
    display: flex;
    font-size: 8pt;
    margin-left: 30px;
    margin-right: 30px;
}

.message-elapsed-left {
    justify-content: left;
}

.message-elapsed-right {
    justify-content: right;
}

@media (max-width: 768px) {

    .message-text {
        font-size: 12pt;
    }

}
