.messages-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
}

.messages-container-mobile {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
    width: 100%;
}

.messages-conversations {
    border-right-width: 1px;
    border-right-style: dotted;
    width: 50%;
}

.messages-conversation {
    height: 100%;
    width: 50%;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {

    .messages-container {
        width: 100;
    }

    .messages-conversations {
        border-right-width: 0px;
        width: 100%;
    }

    .messages-conversation {
        width: 100%;
    }
  
  }