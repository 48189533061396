.event-container {
    align-items: left;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    gap: 12px;
    margin-right: auto;
    padding: 12px;
    padding-left: 16px;
    padding-right: 20px;
    width: 440px;
}

.event-title-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: left;
    width: 100%;
}

.event-title {
    font-size: 12pt;
    font-weight: bold;
}

.event-day-and-time {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: left;
    margin-left: 28px;
}

.event-day {
  font-size: 10pt;
  font-weight: bold;
}
  
.event-time {
  font-size: 10pt;
  font-weight: normal;
}

.event-details {
  max-height: 100px; /* Adjust based on your requirement */
  overflow: hidden;
  margin-left: 28px;
}

.event-detail {
  font-size: 8pt;
  font-weight: normal;
  margin-top: 6px;
}

@media (max-width: 600px) {

  .event-container {
    width: 100%;
  }

}