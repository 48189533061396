.signup-form-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 330px;
}

.signup-form-name-container {
    display: flex;
    flex-direction: row;
    gap: 14px;
    justify-content: space-between;
    width: 100%;
}

.signup-form-birthdate-container {
    display: flex;
    flex-direction: row;
    gap: 14px;
    justify-content: space-between;
    width: 100%;
}

.signup-form-password-requirements {
    font-size: 9pt;
    text-align: left;
    line-height: 1.5em;
    margin-bottom: 10px;
    margin-left: 10px;
    width: 100%;
}