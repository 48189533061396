.menu-container {
    border-right-width: 1px;
    border-right-style: dotted;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 250px; /* Desktop */
    width: 250px; /* Desktop */
    z-index: 1000;
}

.menu-container-mobile {
    border-right-width: 0px;
    display: flex;
    flex-direction: row;
    gap: 6px;
    height: calc(100vh);
    min-width: 350px;
    overflow: none;
    padding-left: 2px;
    padding-right: 6px;
    width: 350px;
}

.menu-right {
    display: flex;
    flex-direction: column;
    height: 100%;
}

@media (max-width: 768px) {

    .menu-right {
        width: 100%;
    }
    
}