.color-popup-container {
    border-width: 1px;
    border-style: solid;
    border-radius: 7px;
    display: inline-block;
    position: relative;
}

.selected-color-square {
    border-radius: 3px;
    cursor: pointer;
    height: 30px;
    width: 60px;
}

.color-popup {
    border-width: 1px;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    padding-left: 14px;
    padding-right: 14px;
    position: fixed;
    z-index: 10000;
}

.color-option {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 7px;
    cursor: pointer;
}

.color-square {
    border-radius: 3px;
    height: 15px;
    margin-right: 10px;
    width: 15px;
}

.color-label {
    font-size: 12px;
}