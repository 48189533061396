.app-settings-wallpaper-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    width: 100%;
}

.app-settings-wallpaper-top-column {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.app-settings-wallpaper-clear-button {
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 10pt;
    font-weight: bold;
    justify-content: center;
    margin-bottom: 20px;
    padding: 14px;
    width: 200px;
}

.app-settings-wallpaper-top {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.app-settings-wallpaper-top-mobile {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.app-settings-wallpaper-bottom {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
}

.app-settings-wallpaper-percentage {
    font-size: 11pt;
    font-weight: bold;
    padding: 20px;
}