.calendar-toolbar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30px;
    height: auto;
    width: 100%;
}

.calendar-toolbar-top {
    box-sizing: border-box;
    align-content: top;
    display: flex;
    height: 100%;
    justify-content: space-between;
    
}

.calendar-toolbar-nav {
    display: flex;
    flex-direction: row;
    padding-top: 2px;
    user-select: none;
    width: auto;
}

.calendar-toolbar-icon {
    cursor: pointer;
    margin-left: 4px;
    margin-top: 4px;
}

.calendar-toolbar-title {
    cursor: pointer;
    font-size: 12pt;
    font-weight: bold;
    margin-left: 16px;
    user-select: none;
}

.calendar-toolbar-pickers {
    cursor: pointer;
    margin-left: 4px;
    margin-top: 4px;
}

.calendar-toolbar-schedule,
.calendar-toolbar-storyline {
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 12pt;
    font-weight: bold;
    height: 100%;
    justify-content: left;
    padding-left: 20px;
    text-wrap: nowrap;
    user-select: none;
}

.calendar-toolbar-wrapper {
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    height: 30px;
    justify-content: left;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {

    .calendar-toolbar-container {
        margin-top: 8px;
    }

}