
.month-cell-container {
    border-right-width: 1px;
    border-right-style: dotted;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    cursor: pointer;
}

.month-cell-input {
    border: 0;
    background-color: transparent;
    font-size: 11pt;
    font-weight: bold;
    margin-right: 12px;
    max-width: 200px;
    outline: none;
    padding: 12px;
}