.folder-grid-item-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;
  position: relative;
  width: 100%;
}

.folder-grid-item-icon-container {
  align-items: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  display: flex;
  height: 121px;
  justify-content: center;
  padding: 30px;
  opacity: 0.6;
  width: 100%;
}