.grid-container {
    display: flex;
    flex-shrink: 1;
    gap: 8px;
    height: 100%;
    overflow: none;
    position: relative;
    width: 100%;
}

.grid-mask {
    border-radius: 17px;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.grid-content {
    display: flex;
    gap: 10px;
    height: 100%;
    overflow-y: auto;
    opacity: 1;
    padding: 16px;
    padding-top: 8px;
    position: relative;
    transition: opacity 0.2s ease-in-out;
    width: 100%;
    z-index: 2;
}

.grid-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.grid-loading {
    text-align: center;
    margin: 20px 0;
    font-size: 16px;
}

@media (max-width: 768px) {

    .grid-container {
        padding: 2px;
    }

}