.site-canvas-block-container {
  border-width: 1px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  padding: 2px;
  min-height: 20px;
  width: 100%;
}

.site-canvas-block-empty {
  margin: auto;
  flex-grow: 1;
}

.site-canvas-block-element {
  align-items: center;
  border-width: 1px;
  display: flex;
  height: calc(100% - 4px) !important;
  justify-content: center;
  margin: 2px;
  overflow: hidden;
  width: calc(100% - 4px) !important;
}