.comments-preview-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.comments-preview-label {
  font-size: 10pt;
  margin-left: 12px;
}

.comments-preview-header {
  padding: 4px;
  padding-left: 0px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.comments-preview-content {
  flex-grow: 1;
  height: auto;
  margin-top: 10px;
  padding-left: 10px;
  width: 100%;
}

.comments-preview-comments-container {
  display: flex;
  flex-direction: row;
  padding-bottom: 12pt;
  padding-top: 12pt;
}

.comments-preview-right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  padding-left: 12px;
}

.comments-preview-header {
  display: flex;
  flex-direction: row;
}

.comments-preview-name {
  font-size: 9pt;
  font-weight: bold;
}

.comments-preview-timestamp {
    font-size: 9pt;
    font-weight: normal;
    margin-left: 4px;
}

.comments-preview-text {
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-size: 10pt;
  line-height: 1.5em;
  padding-right: 12px;
}

.comments-preview-show-reply-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 9pt;
  font-weight: bold;
  width: auto;
}

.comments-preview-show-replies-button {
  border-radius: 11px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  justify-content: center;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  width: 200px;
}