.folder-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  position: relative;
  width: 100%;
}

.folder-grid-container {
  display: flex;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
}

.folder-grid-container-mobile {
  display: flex;
  gap: 16px;
  height: 100%;
  margin-bottom: 50px;
  overflow-y: auto;
  padding: 16px;
}

.folder-add-button,
.folder-add-button-mobile {
    position: absolute;
    right: 20px;
    z-index: 9;
}

.folder-add-button {
    bottom: 20px;
}

.folder-add-button-mobile {
    bottom: 70px;
}