.calendar-week-header-day {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: calc(100% / 7);
  justify-content: center;
  width: auto;
}

.calendar-week-header-day-text {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  font-size: 9pt;
  height: 50px;
  justify-content: center;
  padding: 6px;
  width: 50px;
}
.calendar-week-header-day-date {
  flex-shrink: 1;
  font-size: 12pt;
  font-weight: bold;
}

@media (max-width: 768px) {

  .calendar-week-header-day {
    font-size: 8pt;
  }

}