.event-add-item-button-container {
    display: flex;
    flex-direction: row;
    padding-left: 6px;
    width: 100%;
}

.event-add-item-button-button {
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: center;
    margin-top: 1px;
    width: 20px;
}

.event-add-item-button-label {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    flex-grow: 1;
    padding-left: 12px;
}