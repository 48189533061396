.desktop-container {
    align-items: center;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    transition: background-color 0.3s;
}

.desktop-main {
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 100%;
    position: relative;
    max-width: 100%;
    width: 100%;
}

.desktop-left {
    flex-shrink: 1;
    height: 100%;
    padding-top: 5px;
    width: 63px;
    z-index: 100;
}

.desktop-right {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    max-height: 100%;
    padding-top: 10px;
    width: calc(100% - 63px);
    z-index: 1;
}

.desktop-middle {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: none;
    width: 100%;
}

.desktop-title-bar {
    width: 100%;
}