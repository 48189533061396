.settings-title-container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 16pt;
    font-weight: bold;
    gap: 6px;
    min-height: 30px;
    justify-content: left;
    padding-left: 26px;
    text-wrap: nowrap;
    user-select: none;
    width: 100%;
}