.barcode-modal-activate-button {
  border-radius: 9px;
  height: 40px;
  margin-left: 12px;
  margin-top: 10px;
  padding-left: 16px;
  padding-right: 16px;
  width: 170px;
}

.barcode-modal-activate-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 90px;
  padding-top: 70px;
  width: 100%;
}

.barcode-modal-container {
  align-content: center;
  display: flex;
  flex-direction: column;
}

.barcode-modal-input {
  border-radius: 5px;
  padding: 12px;
  margin-top: 10px;
  margin-bottom: 18px;
}

