.app-settings-theme-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
    width: 760px;
}

.app-settings-theme-column {
    flex-grow: 1;
    padding: 20px;
    padding-top: 10px;
}

@media (max-width: 768px) {

    .app-settings-theme-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .app-settings-theme-column {
        padding-bottom: 30px;
    }

}