.progress-mask {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.progress-content {
  text-align: center;
}

.progress-circle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.progress-status-message {
  font-size: 12pt;
  font-weight: bold;
  margin-top: 50px;
}

.progress-status-flash {
  font-size: 12pt;
  font-weight: bold;
  margin-top: 30px;
}
