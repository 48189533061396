.gallery-summary-preview-container {
  align-items: center;
  border-radius: 3px;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: auto;
  height: auto;
}

.gallery-summary-preview-wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.gallery-summary-preview-image {
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  object-position: center;
  width: 100%;
}