.event-form-container {
    display: flex;
    flex-direction: row;
    height: calc(100% - 20px);
    position: relative;
    width: 100%;
}

.event-form-left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 8px;
    position: relative;
    width: 50%;
  }
  
  .event-form-right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 8px;
    overflow-y: auto;
    position: relative;
    width: 50%;
  }

.event-form-sections {
    height: calc(100% - 70px);
    margin-top: 10px;
    overflow-y: auto;
    padding: 10px;
    width: 100%;
    z-index: 1;
}

.event-form-objects {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.event-form-buttons-left {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 12px;
    justify-content: flex-start;
}

.event-form-buttons-right {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 12px;
    justify-content: flex-end;
}

.event-form-previews {
    background-color: red;
    display: flex;
    flex-direction: row;
    height: 100px;
}

.event-form-preview-item-wrapper {
    height: auto;
    position: relative;
    width: auto;
}

.event-form-preview-item-image {
    height: 100px;
    position: absolute;
    width: 100px;
}