.conversation-toolbar-container {
    align-content: top;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 4px;
    padding-left: 20px;
}

.conversation-toolbar-left {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: left;
    padding-left: 4px;
    text-wrap: nowrap;
    user-select: none;
    width: 70%;
}

.conversation-toolbar-title {
    font-size: 13pt;
    font-weight: bold;
}

.conversation-toolbar-right {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 16px;
    width: 30%;
}