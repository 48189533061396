.form-footer-item-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 12px;
  margin-left: 20px;
}

.form-footer-item-label {
  font-size: 8pt;
  user-select: none;
  width: 60px;
}

.form-footer-item-value {
  font-size: 8pt;
  user-select: none;
}