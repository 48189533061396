.placeholder-cell-container {
    align-items: center;
    border-right-width: 1px;
    border-right-style: dotted;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 43px;
    justify-content: center;
    width: 120px;
}

.placeholder-cell-input {
    border: 0;
    background-color: transparent;
    font-size: 11pt;
    font-weight: bold;
    height: 100%;
    outline: none;
    padding: 11px;
    width: auto;
}