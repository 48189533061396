.date-field-outer-container {
  position: relative;
}

.date-field-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.date-field-input {
  background-color: transparent;
  border: 1px dotted transparent;
  border-radius: 5px;
  font-size: 11pt;
  font-weight: normal;
  margin-top: 6px;
  outline: none;
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 190px;
}

.date-field-label {
  font-size: 10pt;
  margin-left: 12px;
  user-select: none;
}

.date-field-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8px;
}

/* POPUP */
.date-field-popup {
  border-radius: 11px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 1000;
}