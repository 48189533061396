.member-grid-item-container {
  align-items: center;
  border-radius: 11px;
  border: 0px solid;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.member-grid-item-text-fields-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
    width: 100%;
}

.member-grid-item-title {
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  font-weight: bold;
  justify-content: left;
  line-height: 1.3em;
  width: 100%;
}

.member-grid-item-joined {
    display: flex;
    flex-direction: row;
    font-size: 8pt;
    justify-content: left;
    width: 100%;
}

.member-grid-item-image-wrapper {
  align-items: center;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  display: flex;
  flex-direction: row;
  max-height: 200px;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.member-grid-item-image {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}