.checklist-add-form-label {
  font-size: 10pt;
  font-weight: normal;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 12px;
  width: 90%;
}

.checklist-add-form-title-input {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  font-size: 12pt;
  font-weight: bold;
  margin-bottom: 18px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 16px;
  outline: none;
  padding: 12px;
  width: 90%;
}

.checklist-add-form-instructions-input {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  font-size: 10pt;
  font-weight: normal;
  margin-bottom: 18px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 16px;
  outline: none;
  padding: 12px;
  width: 90%;
}