
.image-filter-control-select {
    border-width: 0px;
    border-style: solid;
    border-radius: 5px;
    font-size: 9pt;
    margin-bottom: 16px;
    outline: none;
    padding: 8px;
    text-align: center;
    width: 100%;
}

.image-filter-control-slider-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    margin-bottom: 18px;
    width: 100%;
}

.image-filter-control-slider {
    background: #ddd;
    border-radius: 4px;
    height: 8px;
    position: relative;
    width: 100%;
}

.image-filter-control-thumb {
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
}

.image-filter-control-label {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: right;
    font-size: 10px;
    width: 80px;
}

.image-filter-control-image-container {
    border-radius: 11px;
    height: 100px;
    margin-bottom: 16px;
    margin-top: 20px;
    overflow: hidden;
}

.image-filter-control-image {
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
}
