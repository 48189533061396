.conversation-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    height: 100%;
    width: 100%;
}

.conversation-messages {
    display: flex;
    flex-direction: column-reverse;
    flex-shrink: 1;
    height: 100%;
    overflow-y: auto;
    width: 100%;
}

.conversation-footer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 10px;
    padding: 8px;
    position: relative;
    width: 100%;
}

.conversation-input {
    border-width: 0px;
    border-radius: 24px;
    box-sizing: border-box;
    flex-grow: 1;
    font-size: 11pt;
    margin-left: 12px;
    max-height: 400px;
    outline: none;
    overflow: hidden;
    padding: 16px;
    resize: none;
}

.conversation-send-button {
    align-items: flex-end;
    display: flex;
    padding-bottom: 12px;
    width: 40px;
}

