.user-list-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.user-list-header {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  padding-right: 8px;
}

.user-list-label {
  font-size: 10pt;
  font-weight: normal;
  flex-grow: 1;
  margin-left: 12px;
  user-select: none;
}

.user-list-items {
  margin-left: 8px;
  margin-top: 10px;
}

.user-list-item {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  user-select: none;
  width: 100%;
}

.user-list-item-summary {
  flex-grow: 1;
}

.user-list-item-forward {
  align-self: stretch;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 12px;
  width: 50px;
}

.user-list-summary-no-users {
  display: flex;
  font-size: 11pt;
  font-weight: bold;
  justify-content: center;
  margin-bottom: 16px;
  padding: 18px;
  padding-top: 12px;
  width: 100%;
}
