.field-menu-button {
    align-items: top;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    font-weight: bold;
    height: 30px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 30px;
}
