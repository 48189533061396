.divider-container-horizontal {
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    height: 1px;
    width: 100%;
}

.divider-container-vertical {
    border-right-width: 1px;
    border-right-style: dotted;
    width: 1px;
}