.object-summary-block-container {
  border-width: 1px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}

.object-summary-block-empty {
  margin: auto;
  width: auto;
}

.object-summary-block-element {
  align-items: center;
  display: flex;
  justify-content: center;
  width: auto !important;
  height: auto !important;
}