.calendar-storyline-years-container {
  border-top: 1px solid;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.calendar-storyline-year {
  border-bottom: 1px solid;
  border-right: 1px solid;
  min-width: 100px;
  padding: 10px 0;
  text-align: center;
}