.calendar-storyline-tags-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.calendar-storyline-tag {
  align-items: center;
  border-bottom: 1px solid;
  border-right: 4px solid;
  display: flex;
  height: 100%;
  padding-left: 20px;
  width: 100%;
}