.checkbox-container {
  align-items: center;
  background-color: transparent;
  border-radius: 11px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  padding-top: 4px;
  padding-left: 12px;
  text-align: left;
}

.checkbox-wrapper {
  align-items: center;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.checkbox-label {
  cursor: pointer;
  font-size: 11pt;
  font-weight: normal;
  margin-left: 14px;
  user-select: none;
  width: 100%;
}

.checkbox-field-menu-wrapper {
  padding-right: 8px;
}