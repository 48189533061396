.tile-canvas-button-container {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 10px;
  width: auto;
}

.tile-canvas-button-title {
  font-size: 8pt;
  margin-right: 4px;
}