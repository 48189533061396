.profile-search-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    z-index: 20000;
}

.profile-search-results-container {
    height: calc(100% - 150px);
    overflow-y: auto;
    width: 100%;
}

.profile-search-input {
    border: 0px solid;
    border-radius: 9px;
    font-size: 11pt;
    font-weight: bold;
    outline: none;
    padding: 12px;
    width: 100%;
}

.profile-search-divider {
    height: 1px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}
