.calendar-day-event-container {
  border-radius: 7px;
  cursor: pointer;
  left: 70px;
  overflow: hidden;
  padding: 10px;
  padding-top: 9px;
  pointer-events: auto;
  position: absolute;
  right: 0;
  user-select: none;
  width: 80%;
  z-index: 3;
}

.calendar-day-event-title {
  font-size: 9pt;
  font-weight: bold;
  margin-bottom: 4px;
}

.calendar-day-event-time {
  font-size: 8pt;
  font-weight: normal;
}

.calendar-day-event-details {
  max-height: 100px;
  /* Adjust based on your requirement */
  overflow: hidden;
}

.calendar-day-event-detail {
  font-size: 8pt;
  font-weight: normal;
  margin-top: 6px;
}

.calendar-day-event-resize-handle {
  bottom: 0;
  cursor: grab;
  height: 10px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}