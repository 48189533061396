.profile-tabs-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-tabs-header {
  display: flex;
}

.profile-tab-header {
  flex: 1;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 1px solid;
  padding: 1rem;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
}

.profile-tab-header:last-child {
  border-right: none;
}

.profile-tabs-content {
  position: relative;
  overflow: hidden;
  min-height: 100px;
  padding: 1rem;
  width: 100%;
}

.profile-tab-content {
  position: relative;
}

/* 
  SwitchTransition + CSSTransition use a naming convention for classes:
  <classNames>-enter
  <classNames>-enter-active
  <classNames>-exit
  <classNames>-exit-active
*/

/* ================= Slide Left ================= */
/* 
   These classes define the animation for when a new tab 
   slides in from the right (meaning we moved to a higher index).
*/

/* Initial state: new element enters from 100% right */
.slide-left-enter {
  transform: translateX(100%);
  opacity: 0;
}

/* Final state: new element ends at 0% (fully visible) */
.slide-left-enter.slide-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

/* Initial state: old element starts in place */
.slide-left-exit {
  transform: translateX(0);
  opacity: 1;
}

/* Final state: old element exits to -100% left */
.slide-left-exit.slide-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

/* ================= Slide Right ================= */
/* 
   These classes define the animation for when a new tab 
   slides in from the left (meaning we moved to a lower index).
*/

/* Initial state: new element enters from -100% left */
.slide-right-enter {
  transform: translateX(-100%);
  opacity: 0;
}

/* Final state: new element ends at 0% (fully visible) */
.slide-right-enter.slide-right-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

/* Initial state: old element starts in place */
.slide-right-exit {
  transform: translateX(0);
  opacity: 1;
}

/* Final state: old element exits to 100% right */
.slide-right-exit.slide-right-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}
