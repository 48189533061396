.video-chat-container {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: calc(100vh - 50px);
  width: 100%;
}

.video-chat-container-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 2000px;
}

.video-chat-left {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: 100%;
  overflow-y: auto;
  width: 100%;
}

.video-chat-right {
  border-left: 1px solid;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 30%;
}

.video-chat-title {
  color: white;
  display: flex;
  font-size: 14pt;
  font-weight: bold;
  justify-content: center;
  padding: 12px;
  width: 100%;
}

.video-chat-videos {
  display: flex;
  height: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.video-chat-video-wrapper {
  border-radius: 15px;
  height: auto;
  overflow: hidden;
  width: 50%;
}

.video-chat-videos video {
  height: auto;
  width: 100%;
}

.video-chat-buttons {
  display: flex;
  justify-content: center;
  padding: 12px;
  width: 100%;
}

.video-chat-button {
  background-color: darkslategrey;
  border-width: 0px;
  border-radius: 9px;
  color: white;
  font-size: 11pt;
  font-weight: bold;
  padding: 12px;
}