.relationships-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 2px;
  padding-bottom: 20px;
  padding-left: 4px;
  padding-top: 12px;
}

.relationships-label {
  font-size: 10pt;
  margin-left: 12px;
  user-select: none;
}

.relationships-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8px;
}

.relationships-add-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  padding-left: 14px;
  width: 100%;
}

.relationships-add-button {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: center;
  margin-top: 1px;
  width: 20px;
}

.relationships-add-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 9pt;
  font-weight: normal;
  flex-grow: 1;
  padding-left: 8px;
  width: 100%;
}

.relationships-no-items {
  display: flex;
  font-size: 11pt;
  font-weight: bold;
  justify-content: center;
  margin-bottom: 16px;
  padding: 18px;
  padding-top: 12px;
  width: 100%;
}

.relationships-list-wrapper {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 2px;
  padding-left: 16px;
  padding-right: 20px;
}

.relationships-group {
  margin-top: 6px;
  margin-bottom: 16px;
}

.relationships-group-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.relationships-entry-wrapper {
  align-items: center;
  display: flex;
  justify-content: left;
}

.relationships-entry-icon {
  align-items: center;
  display: flex;
  margin-bottom: 2px;
}

.relationships-entry-title {
  cursor: pointer;
  font-size: 11pt;
  font-weight: normal;
  line-height: 1em;
  padding: 6pt;
  padding-left: 10pt;
}