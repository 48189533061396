.object-summaries-container {
    display: flex;
    gap: 16px;
    height: 100%;
    overflow-y: auto;
    padding: 16px;
    padding-bottom: 0px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.object-summaries-container.hidden {
    opacity: 0;
    visibility: hidden; /* Prevent pointer events */
    transition: none; /* Immediate disappearance */
}

.object-summaries-container.visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in-out;
}

.object-summaries-container-mobile {
    display: flex;
    gap: 16px;
    height: 100%;
    margin-bottom: 50px;
    overflow-y: auto;
    padding: 16px;
}

.object-summaries-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}