.site-canvas-column-container {
  align-items: center;
  border-width: 0px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 0;
  gap: 4px;
  min-width: 20px;
  padding: 2px;
  /* Ensure all children (blocks) are evenly spaced */
  justify-content: space-between;
}

.dragging {
  opacity: 0.5;
}