.context-menu-group-title {
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  font-weight: bold;
  margin-bottom: 4px;
  margin-left: 4px;
  padding: 6px;
  padding-left: 14px;
  padding-right: 14px;
  text-align: left;
  user-select: none;
  width: 100%;
}

.context-menu-group-wrapper {
  margin-bottom: 6px;
}