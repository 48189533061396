.mobile-header-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 60px;
    width: 100%;
}

.mobile-header-left {
    align-items: center;
    display: flex;
    height: 60px;
    min-height: 60px;
    justify-content: center;
    padding-left: 18px;
    user-select: none;
}

.mobile-header-app-title {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: 'Open Sans', 'Roboto', 'Arial';
    font-size: 13pt;
    font-weight: bold;
    height: 50px;
    text-wrap: nowrap;
}

.mobile-header-button {
    align-items: center;
    display: flex;
    height: 60px;
    width: 33px;
}

.mobile-header-icon-wrapper {
    align-items: center;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 20px;
    overflow: hidden;
    position: relative;
    width: 20px;
}

.mobile-header-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the image */
    object-fit: cover;
    width: 100%; /* Default to full width */
    height: 100%; /* This will allow dynamic adjustment */
}

.mobile-header-right {
    align-items: center;
    display: flex;
    height: 100%;
    padding-right: 20px;
    justify-content: right;
    user-select: none;
    width: auto;
}

.mobile-header-theme-toggle {
    margin-right: 12px;
}