.profile-container {
    display: flex;
    flex-direction: column;
    margin: 0px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    width: 100%;
}

.profile-photo-wrapper {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    margin-top: 40px;
    width: 100%;
}

.profile-name-wrapper {
    align-items: center;
    display: flex;
    font-size: 14pt;
    font-weight: bold;
    height: auto;
    justify-content: center;
    margin-top: 14px;
    width: 100%;
}

.profile-stats-wrapper {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    margin-top: 14px;
    width: 100%;
}

.profile-buttons-wrapper {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    margin-top: 18px;
    width: 100%;
}

.profile-tabs-wrapper {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    margin-top: 18px;
    width: 100%;
}