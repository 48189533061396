.profile-photo-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    user-select: none;
    width: 100%;
}

.profile-photo-circle-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: left;
    position: absolute;
    width: auto;
    z-index: 0;
}

.profile-photo-circle {
    align-items: center;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
}

.profile-photo-image {
    width: 100%; 
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.profile-photo-add-button {
    align-items: center;
    border: 5px solid;
    border-radius: 50%;
    bottom: 0;
    cursor: pointer;
    display: flex;
    height: 44px;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 44px;
    z-index: 1;
}