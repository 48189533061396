.calendar-week-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: 100%;
  width: 100%;
}

.calendar-week-header {
  border-bottom: 1px solid;
  display: flex;
  height: 70px;
  min-height: 70px;
  justify-content: space-around;
  margin-left: 60px;
  z-index: 1;
}

.calendar-week-wrapper {
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

.calendar-week-events-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2; /* Ensure this is above hour blocks */
  pointer-events: none;
}
